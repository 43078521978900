<!--setting:Hi, 编辑资料 2024/07/13 22:34:53 YuAng-->

<template>
  <div class="user-setting page-center-content">
    <div class="base-info">
      <div class="piece-title">
        <span>{{ $t('userInfo') }}</span>
      </div>
      <div class="avatar-name-box">
        <div class="avatar-box">
          <div class="face-box">
            <head-img :user="user" size="144px"></head-img>
            <div class="replace" @click="dialogVisible = true">
              <span>{{ $t('changeHead') }}</span>
            </div>
          </div>
        </div>
        <div class="nickname-box">
          <label>
            {{ $t('nickname') }} <span></span>
          </label>
          <div class="nickname">
            <el-input :placeholder="$t('inputContent')" v-model="user.name" maxlength="30">
            </el-input>
          </div>
          <label>{{ $t('intro') }}</label>
          <div class="desc">
            <el-input type="textarea" rows="3" :placeholder="$t('inputContent')" v-model="user.introduction"
              maxlength="30" show-word-limit>
            </el-input>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-button type="primary" @click="save">{{ $t('confirmModify') }}</el-button>
      </div>
    </div>

    <el-dialog :title="$t('uploadImg')" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" width="700px">
      <el-upload v-if="!selectedImg" class="upload-img" drag action="#" :on-change="handleChanged"
        accept="image/jpg,image/jpeg,image/png" multiple :auto-upload="false">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">{{ $t('uploadImgTip') }}<em>{{ $t('uploadImgTipLink') }}</em></div>
      </el-upload>
      <div v-if="selectedImg" class="success-img">
        <div class="img-container">
          <div class="img" :style="{ backgroundImage: 'url(' + selectedImgPath + ')' }"></div>
          <div v-if="uploading" class="progress">
            <el-progress type="circle" :percentage="uploadProgress" :width=146></el-progress>
          </div>
        </div>
        <div class="btns">
          <el-button class="wh100 mr20" @click="cancel">{{ $t('reselect') }}</el-button>
          <el-button class="wh100" type="primary" @click="doUpload">{{ $t('confirm') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import HeadImg from '@/views/components/HeadImg'
import { uploadFile } from '@/utils/aws'
import { userApi } from '@/utils/api'

export default {
  name: 'setting',
  components: { HeadImg },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      selectedImg: false,
      selectedImgPath: '',
      uploading: false,
      dialogVisible: false,
      uploadProgress: 0,
      file: null,
      user: {}
    }
  },
  created () {
    console.log(this.$store.state.user)
    this.user = {
      id: this.$store.state.user.id,
      face: this.$store.state.user.face,
      name: this.$store.state.user.name,
      phone: this.$store.state.user.phone,
      introduction: this.$store.state.user.introduction,
      ip: this.$store.state.user.ip
    }
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    handleChanged: function (file) {
      this.selectedImg = true
      this.selectedImgPath = URL.createObjectURL(file.raw)
      this.file = file
      console.log(this.selectedImgPath)
    },
    save: function () {
      userApi.updateUserInfo({
        name: this.user.name,
        introduction: this.user.introduction,
        face: this.user.face
      }).then(resp => {
        console.log('update user info', resp)
        if (resp.httpStatus === 200) {
          this.$message.success('更新成功')
          // 更新 store
          this.$store.commit('login', this.user)
        }
      })
    },
    cancel: function () {
      this.resetSelected()
    },
    resetSelected: function () {
      this.selectedImg = false
      this.selectedImgPath = ''
      this.file = null
    },
    beforeUpload: function (file) {
      if (this.uploading) {
        this.$message.warn(this.$t('waiteMoment'))
        return
      }
      const isJPG = /\.(jpe?g|png|gif)$/i.test(file.name)

      if (!isJPG) {
        this.$message.error(this.$t('onlyFormat'))
      }
    },
    doUpload: function () {
      if (!this.file) {
        this.$message.error(this.$t('selectPicture'))
        return
      }
      if (this.uploading) {
        this.$message.warn(this.$t('waiteMoment'))
        return
      }
      this.uploading = true
      uploadFile(this.file, 'front/userHead', (loaded, total) => {
        this.uploadProgress = Math.round((loaded / total) * 100)
        console.log(this.uploadProgress)
      }).then(res => {
        this.uploading = false
        this.uploadProgress = 0
        this.user.face = res.Location
        this.resetSelected()
        this.dialogVisible = false
      }).catch(error => {
        this.uploading = false
        console.error('Upload failed:', error)
      })
    }
  }
}
</script>

<style scoped>
.user-setting .base-info {
  height: 360px;
  background-color: rgb(255, 255, 255);
  border-radius: 14px;
  font-weight: 500;
  margin-top: 20px;
  padding: 20px 40px 20px 20px;
}

.user-setting .base-info .piece-title {
  font-size: 18px;
  line-height: 26px;
  line-height: 1;
  margin-bottom: 20px;
  color: rgb(50, 50, 50)
}

.user-setting .base-info .avatar-name-box {
  display: flex;
  height: 215px
}

.user-setting .base-info .avatar-name-box .avatar-box {
  border-right-color: rgb(216, 216, 216);
  border-right: 1px rgb(216, 216, 216);
  border-right-style: solid;
  width: 360px;
  padding-top: 35.5px
}

.user-setting .base-info .avatar-name-box .avatar-box .face-box {
  border-radius: 9999px;
  cursor: pointer;
  height: 9rem;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  width: 9rem
}

.user-setting .base-info .avatar-name-box .avatar-box .face-box .replace {
  height: 100%;
  padding-top: 62px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  color: rgb(255, 255, 255);
  width: 100%;
  background: rgba(0, 0, 0, .3)
}

.user-setting .base-info .avatar-name-box .avatar-box .face-box .replace>span {
  display: block;
}

.user-setting .base-info .avatar-name-box .nickname-box {
  flex: 1 1 0%;
  padding-left: 45px
}

.user-setting .base-info .avatar-name-box .nickname-box>label {
  display: block;
  margin-bottom: 10px;
  color: rgb(185, 185, 185)
}

.user-setting .base-info .avatar-name-box .nickname-box>label>span {
  font-size: 12px;
  color: rgb(248, 113, 113)
}

.user-setting .base-info .avatar-name-box .nickname-box .nickname {
  cursor: text;
  font-weight: 700;
  height: 40px;
  margin-bottom: 30px;
  line-height: 40px;
  border-radius: 7px
}

.user-setting .bottom {
  text-align: right;
  margin-top: 30px
}

.user-setting>>>.el-dialog__wrapper .el-dialog {
  border-radius: 14px;
  padding: 5px;
}

.upload-img {
  margin-bottom: 25px;
  margin-top: 15px;
  height: 200px;
  width: 100%;
}

.upload-img>>>.el-upload {
  width: 100%;
}

.upload-img>>>.el-upload .el-upload-dragger {
  width: 100%;
  border: none;
  background: #eff0f2;
}

.user-setting .success-img {
  margin-bottom: 25px;
  margin-top: 15px;
  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-setting .success-img .img-container {
  width: 144px;
  height: 144px;
  margin-bottom: 20px;
}

.user-setting .success-img .img-container .img {
  background-color: gray;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 9999px;
  overflow: hidden;
  background-color: gray;
}

.user-setting .success-img .btns {
  height: 40px;
}

.img-container {
  position: relative;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.progress {
  position: absolute;
  border-radius: 9999px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.wh100 {
  width: 100px;
}

.mr20 {
  margin-right: 20px;
}
</style>
